import React from 'react';
import { FaPhoneAlt } from 'react-icons/fa';
import './Kontakt.css';

const Page = () => {
  return (
    <div className="contact-page">
      <h2>Kontakt</h2>
      <p>Vid frågor och funderingar når ni oss bäst via SMS:</p>
      <div className="contact-card">
        <ul>
          <li>
            <FaPhoneAlt className="contact-icon" />
            Johanna: <a href="tel:0721935005">072 193 50 05</a>
          </li>
          <li>
            <FaPhoneAlt className="contact-icon" />
            Christian: <a href="tel:0707534649">070 753 46 49</a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Page;