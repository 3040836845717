import { useNavigate } from "react-router-dom";
import Button from "../components/Button";
import image from "./img.jpg";
import "./Main.css";

const Page = () => {
  const navigate = useNavigate();
  return (
    <div className="mainPage">
      <img className="img" src={image} alt="Bild" />
      <div>
        <Button
          type="button"
          onClick={() => {
            window.location.href = "https://forms.gle/G9oXXXX1CxYdmXBr8";
          }}
        >
          OSA HÄR ❤️
        </Button>
        <Button type="button" onClick={() => navigate("/boende")}>
          Boka boende
        </Button>
      </div>
    </div>
  );
};

export default Page;
