import "./App.css";
import Menu from "./components/Menu";
import Main from "./pages/Main";
import Boende from "./pages/Boende";
import Kontakt from "./pages/Kontakt";
import { Route, Routes, BrowserRouter } from "react-router-dom";

const routes = [
  {
    title: "Hem",
    path: "/",
    element: <Main />,
  },
  // {
  //   title: "Checklistan",
  //   path: "/checklistan",
  //   element: <div>Checklistan</div>,
  // },
  {
    title: "Boende",
    path: "/boende",
    element: <Boende />,
  },
  // {
  //   title: "Bra att veta",
  //   path: "/bra-att-veta",
  //   element: <div>Bra att veta</div>,
  // },
  {
    title: "Kontakt",
    path: "/kontakt",
    element: <Kontakt />,
  },
];

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <header className="App-header">
          <Menu routes={routes} type="mobile" />
        </header>
        <main>
          <h1>Christian & Johanna</h1>
          <Menu  routes={routes} type="desktop" />
          <Routes>
            {routes.map((route) => {
              return (
                <Route
                  key={route.path}
                  path={route.path}
                  element={route.element}
                />
              );
            })}
          </Routes>
        </main>
        <footer>
          <span>Marstrand 8/3 2025</span>
        </footer>
      </BrowserRouter>
    </div>
  );
}

export default App;
